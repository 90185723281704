import getCheckoutData from 'utils/getCheckoutData';

const sendDataFilledEvent = (selectedPanelType, amountOfPanels) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event: 'data_filled',
    price: getCheckoutData(selectedPanelType, amountOfPanels),
  });
};

export default sendDataFilledEvent;
