import { object, string } from 'yup';

const getValidationSchema = (intl) => object().shape({
  name: string().when('type', {
    is: 'private',
    then: string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
  }),
  surname: string().when('type', {
    is: 'private',
    then: string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
  }),
  companyName: string().when('type', {
    is: 'company',
    then: string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
  }),
  nip: string().when('type', {
    is: 'company',
    then: string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
  }),
  country: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
  street: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
  houseNumber: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
  postalCode: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' }))
    .matches(/[0-9]{2}[-]?[0-9]{3}/, intl.formatMessage({ id: 'invalidPostalCode' })),
  city: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' })),
  phone: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' }))
    .matches(/^(?:\(?\?)?(?:[-\.\(\)\s]*(\d)){9,99}\)?$/, intl.formatMessage({ id: 'invalidPhoneNumber' })),
  email: string()
    .required(intl.formatMessage({ id: 'fieldIsRequired' }))
    .email(intl.formatMessage({ id: 'invalidEmail' })),
});

export default getValidationSchema;
