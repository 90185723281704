import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl, FormattedMessage, navigate } from 'gatsby-plugin-intl';

import config from 'config';

import OrderSpecs from 'components/OrderSpecs';
import CheckoutHeader from 'components/CheckoutHeader';
import InputText from 'components/InputText';
import RadioGroup from 'components/RadioGroup';
import getData from 'utils/getData';
import countPanels from 'utils/countPanels';
import saveData from 'utils/saveData';
import sendDataFilledEvent from 'utils/sendDataFilledEvent';

import './ConfiguratorPageStep3.scss';
import getValidationSchema from './validation';

const typeOptions = [{
  id: 'private',
  name: <FormattedMessage id="checkoutTypePrivate" />,
}, {
  id: 'company',
  name: <FormattedMessage id="checkoutTypeCompany" />,
}];

const ConfiguratorPageStep3 = ({ typeId }) => {
  const intl = useIntl();
  const [selectedPanelType, setSelectedPanelType] = useState(typeId || config.panelTypes[0].id);
  const panels = getData('configuration');
  const amountOfPanels = countPanels(panels);

  return (
    <div className="configurator-page-step-3">
      <div className="two-columns inline">
        <div className="left">
          <OrderSpecs
            selectedPanelType={selectedPanelType}
            setSelectedPanelType={setSelectedPanelType}
            amountOfPanels={amountOfPanels}
          />
        </div>
        <div className="right">
          <CheckoutHeader
            currentStep={0}
          />
          <Formik
            initialValues={{
              type: 'private',
              name: '',
              surname: '',
              companyName: '',
              nip: '',
              country: '',
              street: '',
              houseNumber: '',
              postalCode: '',
              city: '',
              phone: '',
              email: '',
            }}
            onSubmit={(values) => {
              saveData('checkout-step-3', values);
              sendDataFilledEvent(selectedPanelType, amountOfPanels);
              navigate('/configurator/step-5', { state: { typeId: selectedPanelType } });
            }}
            validationSchema={getValidationSchema(intl)}
          >
            {({ values }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="row type-wrapper">
                    <RadioGroup
                      name="type"
                      options={typeOptions}
                    />
                  </div>
                  <div className="row">
                    {
                      values.type === 'private' ? (
                        <>
                          <div className="half">
                            <InputText
                              name="name"
                              label={<FormattedMessage id="checkoutName" />}
                            />
                          </div>
                          <div className="half">
                            <InputText
                              name="surname"
                              label={<FormattedMessage id="checkoutSurname" />}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="half">
                            <InputText
                              name="companyName"
                              label={<FormattedMessage id="checkoutCompany" />}
                            />
                          </div>
                          <div className="half">
                            <InputText
                              name="nip"
                              label={<FormattedMessage id="checkoutNip" />}
                            />
                          </div>
                        </>
                      )
                    }
                  </div>
                  <div className="row">
                    <InputText
                      name="country"
                      label={<FormattedMessage id="checkoutCountry" />}
                    />
                  </div>
                  <div className="row">
                    <div className="half">
                      <InputText
                        name="street"
                        label={<FormattedMessage id="checkoutStreet" />}
                      />
                    </div>
                    <div className="half">
                      <InputText
                        name="houseNumber"
                        label={<FormattedMessage id="checkoutHouseNumber" />}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="half">
                      <InputText
                        name="postalCode"
                        label={<FormattedMessage id="checkoutPostalCode" />}
                      />
                    </div>
                    <div className="half">
                      <InputText
                        name="city"
                        label={<FormattedMessage id="checkoutCity" />}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <InputText
                      name="phone"
                      label={<FormattedMessage id="checkoutPhone" />}
                    />
                  </div>
                  <div className="row">
                    <InputText
                      name="email"
                      label={<FormattedMessage id="emailAddress" />}
                    />
                  </div>
                </div>
                <div className="button-wrapper">
                  <button className="btn" type="submit">
                    <FormattedMessage id="checkoutNext" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

ConfiguratorPageStep3.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfiguratorPageStep3.defaultProps = {
  typeId: null,
};

export default ConfiguratorPageStep3;
